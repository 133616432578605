import React, { useState, useEffect } from "react";
import ViewInvitation from "./view-invite";
import WeddingCard from "./card";
import RSVP from "./rsvp";
import WeddingDetails from "./details";
import Program from "./program";
import { useParams } from "react-router";
import axios from "axios";

const WeddingInvitation = () => {
  const { slug } = useParams();
  const [showInvitation, setShowInvitation] = useState(false);
  const [guest, setGuest] = useState(null);

  const MainCard = () => {
    return (
      <div>
        <WeddingCard />
        <WeddingDetails />
        <Program />
        <RSVP />
      </div>
    );
  };

  useEffect(() => {
    if (slug && !guest) {
      // Only fetch if there's a slug and guest data is null
      axios
        .get(`${process.env.REACT_APP_BACKEND_API_URL}/rsvp/${slug}/`)
        .then((response) => {
          setGuest(response.data);
        })
        .catch((error) => {
          console.error("Error fetching RSVP data", error);
        });
    }
  }, [slug]);

  const handleButtonClick = () => {
    setShowInvitation(true);
  };

  return (
    <div>
      {showInvitation ? (
        <MainCard />
      ) : (
        <ViewInvitation onButtonClick={handleButtonClick} guest={guest} />
      )}
    </div>
  );
};

export default WeddingInvitation;
