import React from "react";
import FlowerOneBackground from "../../assets/images/flower1-bg.png";
import FlowerTwoBackground from "../../assets/images/flower2-bg.png";
import ButterflyOne from "../../assets/images/butterfly-01.png";
import ButterflyTwo from "../../assets/images/butterfly-02.png";
import CardFiber from "../../assets/images/card_fiber.png";
import ShadowRay from "../../assets/images/shadow_rays.png";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Background from "../../assets/images/background.jpeg";
import { motion, useScroll, useTransform, useAnimation } from "framer-motion";
import CardTexture from "../../assets/images/tx_card_heavy-cotton.png";

const WeddingCard = () => {
  const isMyXsScreen = useMediaQuery("(max-width:600px)");
  const { scrollY } = useScroll();
  const cardX = useTransform(scrollY, [0, 2000], [0, -1000]);
  const cardY = useTransform(scrollY, [0, 2000], [0, 1000]);
  const cardRotate = useTransform(scrollY, [0, 700], [0, 140]);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        height: "100vh",
        overflowY: "hidden",
        overflow: "hidden",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 1,
          zIndex: -1,
        },
      }}
    >
      <Box>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 2,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          style={{
            maxWidth: isMyXsScreen ? "82%" : "62%",
            margin: "0 auto",
            width: "100%",
            position: "relative",
            zIndex: 1,
            borderRadius: "12px",
            boxShadow: "5px 20px 25px rgba(0, 0, 0, 0.15)",
            x: cardX,
            y: cardY,
            rotate: cardRotate,
          }}
        >
          {/* Card content */}
          <Box
            sx={{
              borderRadius: 0.5,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              margin: "0 auto",
              bgcolor: "rgba(143, 161, 139, 1)",
              position: "relative",
              aspectRatio: 0.7,

              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${CardFiber})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: 0.15,
                zIndex: 1,
              },
              zIndex: 2,
              // Floating shadow effect
              boxShadow: "0px 1000px 1000px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              sx={{ height: "100%", paddingY: { md: 0, sm: 0, xs: 1 } }}
            >
              <Typography
                sx={{
                  fontFamily: "Malik-Light",
                  color: "#fff",
                  letterSpacing: 2,
                  fontSize: { md: 13, sm: 12, xs: 10 },
                  pt: { md: 8, sm: 6, xs: 5 },
                  display: "hidden",
                }}
              >
                THE WEDDING RECEPTION OF{" "}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                sx={{ paddingX: { md: 18, sm: 20, xs: 0 } }}
              >
                <Typography
                  sx={{
                    fontFamily: "Bodoni Moda",
                    textAlign: "center",
                    fontSize: { md: 65, sm: 50, xs: 30 },
                    color: "#fff",
                    letterSpacing: 10,
                    lineHeight: 1.2,
                  }}
                >
                  ARYSYA <br /> NIDZAM
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    fontFamily: "Bodoni Moda",
                    color: "#fff",
                    py: 1.5,
                  }}
                >
                  AND
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Bodoni Moda",
                    textAlign: "center",
                    fontSize: { md: 65, sm: 50, xs: 30 },
                    color: "#fff",
                    paddingX: 16,
                    letterSpacing: 10,
                    lineHeight: 1.2,
                  }}
                >
                  CHARLES <br /> KING
                </Typography>
              </Box>
              <Box sx={{ paddingBottom: { md: 8, sm: 6, xs: 5 } }}>
                <Typography
                  sx={{
                    fontFamily: "Malik-LightItalic",
                    // color: "#e0d8cb",
                    color: "#fff",
                    letterSpacing: 2,
                    fontSize: 12,
                    textAlign: "center",
                    py: 1,
                    filter:
                      "drop-shadow(rgba(0, 0, 0, 0.95) -1px -1px 0px) drop-shadow(rgba(255, 255, 255, 0) 1px 1px 0px)",
                  }}
                >
                  on
                </Typography>
                <Typography
                  sx={{
                    fontSize: { md: 18, sm: 16, xs: 15 },
                    textAlign: "center",
                    fontFamily: "Malik-Light",
                    color: "#fff",
                    letterSpacing: 3,
                    filter:
                      "drop-shadow(rgba(0, 0, 0, 0.95) -1px -1px 0px) drop-shadow(rgba(255, 255, 255, 0) 1px 1px 0px)",
                  }}
                >
                  19.01.2025
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* Butterflies */}
          <motion.img
            src={ButterflyOne}
            style={{
              position: "absolute",
              top: 10,
              left: "-18%",
              width: "40%",
              objectFit: "cover",
              zIndex: 100,
            }}
          />
          <motion.img
            src={ButterflyTwo}
            style={{
              position: "absolute",
              bottom: 20,
              right: "-18%",
              width: "40%",
              objectFit: "cover",
              zIndex: 100,
            }}
          />
        </motion.div>
      </Box>
      <motion.img
        src={ShadowRay}
        style={{
          position: "absolute",
          bottom: 0,
          top: 0,
          height: "100vh",
          width: "100%",
          objectFit: "cover",
          zIndex: 1,
          opacity: 0,
        }}
      />
    </Box>
  );
};

export default WeddingCard;
