import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  NativeSelect,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import { useParams } from "react-router-dom";
import Background from "../../assets/images/background.jpeg";
import BackgroundThree from "../../assets/images/background4.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import CardFiber from "../../assets/images/card_fiber.png";

const RSVP = ({}) => {
  const { slug } = useParams();
  const [rsvp, setRSVP] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    additional: "",
    total_attending: "",
    attendance_confirmation: null,
    note: "",
  });
  const [showRSVP, setShowRSVP] = useState(false);
  const [isAttending, setIsAttending] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const rsvpRef = useRef(null);
  const detailsRef = useRef(null);

  const handleClick = () => {
    setShowRSVP((prevShowRSVP) => !prevShowRSVP);
  };

  useEffect(() => {
    if (slug) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_API_URL}/rsvp/${slug}/`)
        .then((response) => {
          setRSVP(response.data);
          setFormData({
            attendance_confirmation: response.data.attendance_confirmation,
            total_attending: response.data.total_attending,
            note: response.data.note || "",
            name: response.data.name || "",
            additional: response.data.additional || "",
          });
          setIsSubmitted(response.data.attendance_confirmation !== null);
        })
        .catch((error) => {
          console.error("Error fetching RSVP data", error);
        });
    }
    if (showRSVP) {
      rsvpRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [slug, showRSVP]);

  useEffect(() => {
    if (isAttending && detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isAttending]);

  const handleAttendance = (attending) => {
    setFormData((prev) => ({
      ...prev,
      attendance_confirmation: attending,
    }));
    setIsAttending(attending);
  };

  const handlePrivateNoteChange = (e) => {
    setFormData((prev) => ({ ...prev, note: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const url = slug
      ? `${process.env.REACT_APP_BACKEND_API_URL}/rsvp/${slug}/update_with_slug/`
      : `${process.env.REACT_APP_BACKEND_API_URL}/rsvp/`;

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(url, formData, { headers })
      .then((response) => {
        alert(
          "Thank you! Your response has been received. We can’t wait to celebrate with you."
        );
        setShowRSVP(false);
        setIsSubmitted(true);
      })
      .catch((error) => {
        alert(
          error.response?.data?.error ||
            "Unable to send your RSVP. Please try again."
        );
      });
  };

  const handleFirstNameChange = (e) => {
    setFormData((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleTotalAttending = (e) => {
    const value = parseInt(e.target.value, 10);
    setFormData((prev) => ({ ...prev, total_attending: e.target.value }));
  };

  return (
    <>
      <Box
        onClick={handleClick}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{
          position: "relative",
          py: 20,
          px: { md: 40, sm: 15, xs: 3 },
          overflow: "hidden",
          backgroundImage: `url(${BackgroundThree})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // "&::before": {
          //   content: '""',
          //   position: "absolute",
          //   top: 0,
          //   left: 0,
          //   right: 0,
          //   bottom: 0,
          //   backgroundImage: `url(${Background})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          //   zIndex: -1,
          // },
          cursor: "pointer",
          "&:hover::before": {
            opacity: 0.9,
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Balerno-Lt",
            py: 2,
            fontSize: 30,
            letterSpacing: 5,
            textAlign: "center",
            backgroundColor: "#8fa18b",
            color: "#fff",
            cursor: "pointer",
            px: 5,
          }}
        >
          CLICK HERE TO RSVP
        </Typography>
      </Box>
      {showRSVP && (
        <Box
          ref={rsvpRef}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            py: 8,
            backgroundColor: "rgba(143, 161, 139, 0.9)",
            px: 3,
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${CardFiber})`, // CardFiber as background image
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundBlendMode: "multiply",
              opacity: 0.05,
              zIndex: 1,
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Balerno-Lt",
              fontSize: 30,
              fontWeight: 600,
              textAlign: "center",
              color: "#fff",
              py: 1,
            }}
          >
            RSVP
          </Typography>
          <Typography
            sx={{ fontFamily: "Balerno-Lt", color: "#fff", fontSize: 20 }}
          >
            Kindly Respond by 15 December 2024
          </Typography>
          {rsvp ? (
            <Box sx={{ paddingTop: 3 }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontFamily: "Balerno-Lt",
                  color: "#fff",
                  textAlign: "center",
                  pt: 2,
                }}
              >
                {rsvp.name} {rsvp.additional}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ paddingTop: 3 }}>
              <Typography
                style={{
                  fontSize: 15,
                  fontFamily: "Balerno-Bold",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                TO
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Balerno-Bold",
                  fontSize: 20,
                  paddingTop: 1,
                  paddingBottom: 2.5,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                Our Honoured Guests
              </Typography>
            </Box>
          )}

          {isSubmitted ? (
            <>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontFamily: "Balerno-Lt",
                  pt: 5,
                  color: "#fff",
                  fontSize: 30,
                  textAlign: "center",
                }}
              >
                Thank you for your response!
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  fontFamily: "Balerno-Lt",
                  color: "#fff",
                  textAlign: "center",
                  px: { md: 40, sm: 15, xs: 3 },
                }}
              >
                We appreciate your response. If you have any questions or need
                further information, feel free to reach out. We look forward to
                seeing you if you can make it!
              </Typography>
            </>
          ) : (
            <>
              <Box
                display="flex"
                gap={1}
                sx={{
                  flexDirection: { md: "row", sm: "row", xs: "column" },
                  mt: 3,
                }}
              >
                {rsvp ? (
                  <>
                    <Button
                      sx={{
                        backgroundColor:
                          isAttending === true ? "white" : "transparent",
                        border: "1px solid white",
                        color: isAttending === true ? "#142E24" : "white",
                        borderRadius: 0,
                        width: "200px",
                        fontSize: 18,
                        my: 0.5,
                        fontFamily: "Balerno-Lt",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.1)",
                        },
                        textTransform: "none",
                      }}
                      onClick={() => handleAttendance(true)}
                    >
                      Will Attend
                    </Button>
                    <Button
                      sx={{
                        backgroundColor:
                          isAttending === false ? "white" : "transparent",
                        border: "1px solid white",
                        color: isAttending === false ? "#142E24" : "white",
                        borderRadius: 0,
                        width: "200px",
                        fontSize: 18,
                        my: 0.5,
                        fontFamily: "Balerno-Lt",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.1)",
                        },
                        textTransform: "none",
                      }}
                      onClick={() => handleAttendance(false)}
                    >
                      Will Not Attend
                    </Button>
                  </>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Balerno-Lt",
                        color: "#fff",
                        fontSize: 15,
                        pb: 1,
                        textAlign: "center",
                        px: { md: 35, sm: 20, xs: 0 },
                      }}
                    >
                      We are delighted to invite you to join us in celebrating
                      our special day. To ensure an exclusive and memorable
                      experience for each of our beloved guests, access to our
                      wedding website is granted by personalized invitation
                      only.
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Balerno-Lt",
                        fontSize: 15,
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        textAlign: "center",
                        color: "#fff",
                        px: { md: 25, sm: 20, xs: 0 },
                      }}
                    >
                      If you have not yet received your personalized invitation,
                      we kindly ask you to reach out to us using the contact
                      information provided below.
                    </Typography>
                    <Box
                      display="flex"
                      sx={{
                        mt: 3,
                        flexDirection: {
                          md: "row",
                          sm: "column",
                          xs: "column",
                        },
                      }}
                    >
                      <Button
                        onClick={() =>
                          window.open("https://wa.me/60183954307", "_blank")
                        }
                        startIcon={<WhatsAppIcon />}
                        sx={{
                          backgroundColor: "transparent",
                          border: "1px solid #fff",
                          color: "#fff",
                          borderRadius: 0,
                          width: "250px",
                          fontSize: 18,
                          fontFamily: "Balerno-Bold",
                          textAlign: "center",
                          py: 1,
                          mx: 0.5,
                          my: 0.5,
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                          },
                          textTransform: "none",
                        }}
                      >
                        Arysya (The Bride)
                      </Button>
                      <Button
                        startIcon={<WhatsAppIcon />}
                        onClick={() =>
                          window.open("https://wa.me/60126477810", "_blank")
                        }
                        sx={{
                          backgroundColor: "transparent",
                          border: "1px solid #fff",
                          color: "#fff",
                          borderRadius: 0,
                          width: "250px",
                          fontSize: 18,
                          mx: 0.5,
                          my: 0.5,
                          py: 1,
                          fontFamily: "Balerno-Bold",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                          },
                          textTransform: "none",
                        }}
                      >
                        Sonia
                      </Button>
                      <Button
                        startIcon={<WhatsAppIcon />}
                        onClick={() =>
                          window.open("https://wa.me/60176300314", "_blank")
                        }
                        sx={{
                          backgroundColor: "transparent",
                          border: "1px solid #fff",
                          color: "#fff",
                          borderRadius: 0,
                          width: "250px",
                          mx: 0.5,
                          my: 0.5,
                          py: 1,
                          fontSize: 18,
                          fontFamily: "Balerno-Bold",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                          },
                          textTransform: "none",
                        }}
                      >
                        Amalia
                      </Button>
                    </Box>
                    {/* <Button
                      sx={{
                        backgroundColor:
                          isAttending === true ? "white" : "transparent",
                        border: "1px solid white",
                        color: isAttending === true ? "#142E24" : "white",
                        borderRadius: 0,
                        width: "200px",
                        fontSize: 18,
                        my: 0.5,
                        fontFamily: "Balerno-Lt",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.1)",
                        },
                        textTransform: "none",
                      }}
                      onClick={() => handleAttendance(true)}
                    >
                      Yes
                    </Button> */}
                  </Box>
                )}
              </Box>

              {isAttending !== null && (
                <form
                  ref={detailsRef}
                  onSubmit={handleSubmit}
                  style={{
                    width: "100%",
                    maxWidth: 500,
                    marginTop: 20,
                    color: "#fff",
                  }}
                >
                  {!slug && (
                    <Box display="flex" justifyContent="center" mb={2}>
                      <TextField
                        placeholder="First Name"
                        variant="standard"
                        value={formData.name}
                        onChange={handleFirstNameChange}
                        margin="normal"
                        required
                        sx={{
                          width: "45%",
                          marginRight: 2,
                          "& .MuiInputBase-input": {
                            color: "white",
                            fontFamily: "Balerno-Lt",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "white",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "white",
                          },
                        }}
                      />
                    </Box>
                  )}
                  {isAttending && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          fontFamily: "Balerno-Lt",
                          fontSize: 20,
                          textAlign: "center",
                          pt: 2,
                        }}
                      >
                        Total Attending
                      </Typography>
                      <NativeSelect
                        value={formData.total_attending}
                        onChange={handleTotalAttending}
                        IconComponent={KeyboardArrowDownOutlinedIcon}
                        sx={{
                          width: "45px",
                          my: 2,
                          fontFamily: "Balerno-Bold",
                          fontSize: 20,
                          color: "#fff",
                          textAlign: "center",
                          "& .MuiNativeSelect-icon": {
                            color: "#fff",
                          },
                          "& .MuiInputBase-root:before, & .MuiInputBase-root:after":
                            {
                              borderBottom: "none",
                            },
                          "&:before, &:after": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        {[0, 1, 2, 3, 4, 5, 6].map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </NativeSelect>
                    </Box>
                  )}

                  <TextField
                    placeholder="Send a wish to the wedded couple..."
                    variant="standard"
                    value={formData.note}
                    onChange={handlePrivateNoteChange}
                    fullWidth
                    margin="normal"
                    sx={{
                      textAlign: "center",
                      "& .MuiInputBase-input": {
                        color: "white",
                        fontFamily: "Balerno-Lt",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "white",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "white",
                      },
                    }}
                    InputLabelProps={{
                      shrink: false,
                    }}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    fullWidth
                    sx={{
                      mt: 1,
                      mb: 2,
                      backgroundColor: "white",
                      border: "1px solid white",
                      color: "#142E24",
                      borderRadius: 0,
                      fontSize: 18,
                      fontFamily: "Balerno-Lt",
                      my: 3,
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </form>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default RSVP;
