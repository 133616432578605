import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ShadowTwo from "../../assets/images/shadow-02.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Background from "../../assets/images/background.jpeg";

const ContactForInvitation = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      flexDirection="column"
      style={{
        height: "100vh",
        width: "100%",
        overflowY: "auto",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        flexDirection="column"
        sx={{ px: 4, overflowY: "auto" }}
      >
        <Box>
          <Typography
            style={{
              fontSize: 15,
              fontFamily: "Balerno-Bold",
              textAlign: "center",
            }}
          >
            TO
          </Typography>
          <Typography
            sx={{
              fontFamily: "Balerno-Bold",
              fontSize: 20,
              paddingTop: 2.5,
              paddingBottom: 2.5,
              textAlign: "center",
            }}
          >
            Our Honoured Guests
          </Typography>
          <Typography
            sx={{
              fontFamily: "Balerno-Bold",
              fontSize: 15,
              paddingTop: 2.5,
              paddingBottom: 2.5,
              textAlign: "center",
              px: { md: 35, sm: 20, xs: 0 },
            }}
          >
            We are delighted to invite you to join us in celebrating our special
            day. To ensure an exclusive and memorable experience for each of our
            beloved guests, access to our wedding website is granted by
            personalized invitation only.
          </Typography>
          <Typography
            sx={{
              fontFamily: "Balerno-Bold",
              fontSize: 15,
              paddingTop: 2.5,
              paddingBottom: 2.5,
              textAlign: "center",
              px: { md: 25, sm: 20, xs: 0 },
            }}
          >
            If you have not yet received your personalized invitation, we kindly
            ask you to reach out to us using the contact information provided
            below.
          </Typography>
        </Box>
        <Box
          display="flex"
          sx={{
            mt: 3,
            flexDirection: { md: "row", sm: "column", xs: "column" },
          }}
        >
          <Button
            onClick={() => window.open("https://wa.me/60183954307", "_blank")}
            startIcon={<WhatsAppIcon />}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #000",
              color: "#000",
              borderRadius: 0,
              width: "250px",
              fontSize: 18,
              fontFamily: "Balerno-Bold",
              textAlign: "center",
              py: 1,
              mx: 0.5,
              my: 0.5,
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
              textTransform: "none",
            }}
          >
            Arysya (The Bride)
          </Button>
          <Button
            startIcon={<WhatsAppIcon />}
            onClick={() => window.open("https://wa.me/60126477810", "_blank")}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #000",
              color: "#000",
              borderRadius: 0,
              width: "250px",
              fontSize: 18,
              mx: 0.5,
              my: 0.5,
              py: 1,
              fontFamily: "Balerno-Bold",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
              textTransform: "none",
            }}
          >
            Sonia
          </Button>
          <Button
            startIcon={<WhatsAppIcon />}
            onClick={() => window.open("https://wa.me/60176300314", "_blank")}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #000",
              color: "#000",
              borderRadius: 0,
              width: "250px",
              mx: 0.5,
              my: 0.5,
              py: 1,
              fontSize: 18,
              fontFamily: "Balerno-Bold",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
              textTransform: "none",
            }}
          >
            Amalia
          </Button>
        </Box>
      </Box>
      <img
        src={Background}
        style={{
          position: "absolute",
          bottom: 0,
          top: 0,
          height: "100vh",
          width: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      />
    </Box>
  );
};

export default ContactForInvitation;
