import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import {
  motion,
  useScroll,
  useTransform,
  useAnimation,
  motionValue,
  color,
} from "framer-motion";
import BackgroundGreen from "../../assets/images/background5.png";

const Program = () => {
  const [scrollY, setScrollY] = useState(0);
  const programControls = useAnimation();
  const dressCodeControls = useAnimation();

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Trigger the animation based on scrollY position for date
    const threshold = 1200; // Adjust this value as needed
    if (scrollY > threshold) {
      programControls.start({ opacity: 1, y: 0 });
    } else {
      programControls.start({ opacity: 0, y: 20 });
    }

    // Trigger the animation for "Dress Code" section
    if (scrollY > threshold + 400) {
      dressCodeControls.start({ opacity: 1, y: 0 });
    } else {
      dressCodeControls.start({ opacity: 0, y: 20 });
    }
  }, [scrollY, , programControls, dressCodeControls]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        py: 10,
        backgroundColor: "#868872",
        px: { md: 30, sm: 5, xs: 3 },
        backgroundImage: `url(${BackgroundGreen})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        overflow: "hidden",
      }}
    >
      <motion.div
        animate={programControls}
        transition={{ duration: 1.5 }}
        initial={{ opacity: 0, y: 100 }} // Start position for slide up effect
        style={{ width: "100%", py: 2 }}
      >
        <Typography
          style={{
            color: "#000",
            fontFamily: "Balerno-Bold",
            fontSize: 23,
            textAlign: "center",
            paddingBottom: 30,
          }}
        >
          PROGRAM
        </Typography>
        <Grid container>
          <Grid item xs={6} sx={{ paddingRight: { md: 2, sm: 0, xs: 0 } }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Bold",
                fontSize: 18,
                textAlign: { md: "right", sm: "center", xs: "center" },
              }}
            >
              11:30 AM - 12:00 PM
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: 3 }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Lt",
                fontSize: 20,
              }}
            >
              Arrival of Guests
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={6} sx={{ paddingRight: { md: 2, sm: 0, xs: 0 } }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Bold",
                fontSize: 18,
                textAlign: { md: "right", sm: "center", xs: "center" },
              }}
            >
              12:10 PM
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: 3 }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Lt",
                fontSize: 20,
              }}
            >
              Entrance of Bride & Groom
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={6} sx={{ paddingRight: { md: 2, sm: 0, xs: 0 } }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Bold",
                fontSize: 18,
                textAlign: { md: "right", sm: "center", xs: "center" },
              }}
            >
              12:15 PM
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: 3 }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Lt",
                fontSize: 20,
              }}
            >
              Doa
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={6} sx={{ paddingRight: { md: 2, sm: 0, xs: 0 } }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Bold",
                fontSize: 18,
                textAlign: { md: "right", sm: "center", xs: "center" },
              }}
            >
              12:20 PM
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: 3 }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Lt",
                fontSize: 20,
              }}
            >
              Salam Restu
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={6} sx={{ paddingRight: { md: 2, sm: 0, xs: 0 } }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Bold",
                fontSize: 18,
                textAlign: { md: "right", sm: "center", xs: "center" },
              }}
            >
              12:45 PM
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: 3 }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Lt",
                fontSize: 20,
              }}
            >
              Lunch is Served
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={6} sx={{ paddingRight: { md: 2, sm: 0, xs: 0 } }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Bold",
                fontSize: 18,
                textAlign: { md: "right", sm: "center", xs: "center" },
              }}
            >
              2:30 PM
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: 3 }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Lt",
                fontSize: 20,
              }}
            >
              Farewell
            </Typography>
          </Grid>
        </Grid>
      </motion.div>

      <motion.div
        animate={dressCodeControls}
        transition={{ duration: 1.5 }}
        initial={{ opacity: 0, y: 20 }} // Start position for slide up effect
      >
        <Box sx={{ paddingTop: 10 }}>
          <Typography
            style={{
              color: "#000",
              fontFamily: "Balerno-Bold",
              fontSize: 23,
              textAlign: "center",
            }}
          >
            DRESS CODE
          </Typography>
          <Box sx={{ paddingTop: 3 }}>
            <Typography
              style={{
                color: "#000",
                fontFamily: "Balerno-Lt",
                fontSize: 20,
              }}
            >
              Traditional/Formal Attire
            </Typography>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
};

export default Program;
