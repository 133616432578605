import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, useMediaQuery, Divider } from "@mui/material";
import {
  motion,
  useScroll,
  useTransform,
  useAnimation,
  motionValue,
  color,
} from "framer-motion";
import { useParams } from "react-router";
import axios from "axios";
import Countdown from "../countdown";
import DriedFlower from "../../assets/images/dried-flower.png";
import BackgroundGreen from "../../assets/images/background6.png";
import BackgroundThree from "../../assets/images/background3.png";
import CardFiber from "../../assets/images/card_fiber.png";
import ShadowRay from "../../assets/images/shadow_rays.png";
import ButterflyOne from "../../assets/images/butterfly-01.png";
import FreshFlower from "../../assets/images/fresh-flower.png";

const WeddingDetails = () => {
  const [scrollY, setScrollY] = useState(0);
  const surahControl = useAnimation();
  const parentsControl = useAnimation();
  const countdownControls = useAnimation();
  const detailsControl = useAnimation();
  const themeContols = useAnimation();
  const butterflyControl = useAnimation();

  const { slug } = useParams();
  const [rsvp, setRSVP] = useState(null);
  const isMyXsScreen = useMediaQuery("(max-width:600px)");

  // useEffect(() => {
  //   if (slug) {
  //     axios
  //       .get(`${process.env.REACT_APP_BACKEND_API_URL}/rsvp/${slug}/`)
  //       .then((response) => {
  //         setRSVP(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching RSVP data", error);
  //       });
  //   }
  // }, [slug]);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Trigger the animation based on scrollY position for date
    const threshold = 300; // Adjust this value as needed
    if (scrollY > threshold) {
      parentsControl.start({ opacity: 1, y: 0 });
    } else {
      parentsControl.start({ opacity: 0, y: 20 });
    }

    // Trigger the animation for "Dress Code" section
    if (scrollY > threshold + 400) {
      detailsControl.start({ opacity: 1, y: 0 });
    } else {
      detailsControl.start({ opacity: 0, y: 20 });
    }

    if (scrollY > threshold + 700) {
      butterflyControl.start({ x: isMyXsScreen ? 230 : 500 });
    } else {
      butterflyControl.start({ x: 0 });
    }
    // Trigger the animation for "Let's Celebrate!" section
    if (scrollY > threshold + 700) {
      countdownControls.start({ opacity: 1, y: 0 });
    } else {
      countdownControls.start({ opacity: 0, y: 20 });
    }

    // Trigger the animation for "Colour Theme" section
    if (scrollY > threshold + 1200) {
      themeContols.start({ opacity: 1, y: 0 });
    } else {
      themeContols.start({ opacity: 0, y: 20 });
    }
  }, [
    scrollY,
    ,
    parentsControl,
    countdownControls,
    detailsControl,
    themeContols,
    butterflyControl,
  ]);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          py: 10,
          backgroundColor: "#868872",
          backgroundImage: `url(${BackgroundGreen})`,
          backgroundSize: "cover",
          height: "100%",
          backgroundPosition: "top",
        }}
      >
        <motion.div
          animate={parentsControl}
          transition={{ duration: 1.5 }}
          initial={{ opacity: 0, y: 20 }} // Start position for slide up effect
        >
          <Typography
            sx={{ textAlign: "center", color: "#000", fontSize: 40, pb: 2 }}
          >
            ﷽
          </Typography>
          <Typography
            style={{
              fontSize: 15,
              color: "#000",
              fontFamily: "Balerno-Lt",
              textAlign: "center",
            }}
          >
            In the name of the almighty Allah
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ pt: 5 }}
          >
            <Typography
              style={{
                fontSize: 20,
                color: "#000",
                fontFamily: "Balerno-Bold",
                textAlign: "center",
              }}
            >
              The family of
            </Typography>
            {/* <Typography
            sx={{
              color: "#fff",
              fontFamily: "Balerno-Bold",
              fontSize: 25,
              textAlign: "center",
              pb: 8,
              pt: 2,
              px: { md: 10, sm: 5, xs: 3 },
            }}
          >
            {rsvp ? ` ${rsvp.name} ${rsvp.additional}` : "Our Honoured Guests"}
          </Typography> */}
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Balerno-Bold",
                fontSize: 25,
                textAlign: "center",
                px: { md: 30, sm: 5, xs: 3 },
                pt: 1.5,
              }}
            >
              Allahyarham Dato’ Seri Mohd Salleh Ahmad <br />
              <Typography sx={{ fontFamily: "Playball" }}> and </Typography>
              Allahyarham Datin Seri Datuk Lily Majeed <br />
            </Typography>
            <br />
            <Box sx={{ px: { md: 30, sm: 5, xs: 1 } }}>
              <Typography
                style={{
                  fontSize: 20,
                  color: "#000",
                  fontFamily: "Balerno-Lt",
                  textAlign: "center",
                }}
              >
                would like to request the pleasure of your presence to the
                wedding of
              </Typography>
            </Box>
            <br />
            <Typography
              style={{
                fontSize: 35,
                color: "#000",
                fontFamily: "Balerno-Bold",
                textAlign: "center",
              }}
            >
              Arysya Nidzam <br />
              <Typography sx={{ fontFamily: "Playball" }}> and </Typography>
              Charles King <br />
            </Typography>
            <motion.div
              animate={detailsControl}
              transition={{ duration: 1.5 }}
              initial={{ opacity: 0, y: 20 }} // Start position for slide up effect
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ paddingTop: 10 }}
              >
                <Box sx={{ paddingBottom: 2 }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Balerno-Lt",
                      fontSize: 14,
                      letterSpacing: 2,
                      textAlign: "center",
                      paddingBottom: 1,
                    }}
                  >
                    DATE
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Balerno-Bold",
                      fontSize: 22,
                      letterSpacing: 2,
                      textAlign: "center",
                    }}
                  >
                    19 January 2025, Sunday
                  </Typography>
                </Box>
                {/* <Divider style={{ width: "15%", backgroundColor: "#fff" }} /> */}
                <Box sx={{ paddingTop: 3 }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Balerno-Lt",
                      fontSize: 14,
                      letterSpacing: 2,
                      textAlign: "center",
                      paddingBottom: 1,
                    }}
                  >
                    VENUE
                  </Typography>
                  <Box sx={{ px: { md: 30, sm: 5, xs: 3 } }}>
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Balerno-Bold",
                        fontSize: 22,
                        letterSpacing: 2,
                        textAlign: "center",
                      }}
                    >
                      Crystal Crown Hotel, Petaling Jaya
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Balerno-Lt",
                        fontSize: 18,
                        letterSpacing: 2,
                        textAlign: "center",
                      }}
                    >
                      12, Lorong Utara A, Off, Jln Utara, <br /> 46200 Petaling
                      Jaya, Selangor.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </motion.div>
          </Box>
        </motion.div>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{
          position: "relative",
          pt: { md: 10, sm: 8, xs: 6 },
          backgroundColor: "#8FA18B",
          backgroundImage: `url(${BackgroundThree})`,
          backgroundPosition: "center",
          height: "100%",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        {/* <img
          src={ShadowRay}
          style={{
            position: "absolute", // Absolute positioning within the Box
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3, // Higher z-index to place above other components
            pointerEvents: "none", // Prevents interference with other elements
            opacity: 0,
          }}
        /> */}
        <img
          src={FreshFlower}
          style={{
            position: "absolute",
            bottom: 0,
            right: isMyXsScreen ? -220 : 0,
            width: isMyXsScreen ? "100%" : "35%",
            objectFit: "cover",
            zIndex: 100,
          }}
        />
        <motion.div
          animate={countdownControls}
          transition={{ duration: 1 }}
          initial={{ opacity: 0, y: 20 }} // Start position for slide up effect
        >
          <Box sx={{ width: "100%" }}>
            <motion.img
              animate={butterflyControl}
              transition={{ duration: 10 }}
              src={ButterflyOne}
              style={{
                position: "relative",
                left: isMyXsScreen ? "10%" : "40%",
                width: isMyXsScreen ? "30%" : "10%",
                objectFit: "cover",
                zIndex: 100,
              }}
            />
          </Box>
        </motion.div>
        <Box
          display="flex"
          sx={{
            width: { md: "60%", sm: "80%", xs: "90%" },
            marginTop: 5,
            backgroundColor: "rgba(143, 161, 139, 0.9)",
            py: { md: 5, sm: 7, xs: 3 },
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${CardFiber})`, // CardFiber as background image
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundBlendMode: "multiply",
              opacity: 0.15,
              zIndex: 1,
            },
          }}
        >
          <motion.div
            animate={countdownControls}
            transition={{ duration: 2 }}
            initial={{ opacity: 0, y: 20 }} // Start position for slide up effect'
            style={{
              display: "flex",
              width: "100%",
              paddingLeft: isMyXsScreen ? 20 : 80,
              paddingRight: isMyXsScreen ? 20 : 80,
              paddingTop: 40,
              justifyContent: "center",
              zIndex: 2,
              overflowY: "hidden",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "Playball",
                  fontSize: { md: 30, sm: 25, xs: 23 },
                  lineHeight: 1.2,
                  textAlign: "center",
                  pb: 2,
                }}
              >
                “And among His signs is that He created for you mates from among
                yourselves, that you may dwell in tranquillity with them, and He
                has put love and mercy between your hearts.”
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "Balerno-lt",
                  fontSize: { md: 20, sm: 18, xs: 15 },
                  textAlign: "center",
                  pt: 3,
                }}
              >
                -- Surah Ar-Rum, Verse 21 --
              </Typography>
            </Box>
          </motion.div>
        </Box>
      </Box>
    </Box>
  );
};

export default WeddingDetails;
