import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WeddingInvitation from "./pages";
import ContactForInvitation from "./pages/contact-invitation";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* RSVP Route that captures the slug */}
        <Route path="/" element={<ContactForInvitation />} />
        <Route path="/:slug" element={<WeddingInvitation />} />
      </Routes>
    </Router>
  );
};

export default App;
