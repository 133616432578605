import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { motion } from "framer-motion";
import { Box, Button, Typography } from "@mui/material";
import ShadowTwo from "../../assets/images/shadow-02.png";
import Music from "../../assets/music/music.mp3";
import Background from "../../assets/images/background.jpeg";

const ViewInvitation = ({ guest, onButtonClick }) => {
  // const { slug } = useParams();
  // const [guest, setGuest] = useState(null);
  const [audio] = useState(() => {
    const newAudio = new Audio(Music);
    newAudio.volume = 0.2; // Set volume to 30%
    return newAudio;
  });

  // useEffect(() => {
  //   if (slug) {
  //     // Fetch RSVP details using the slug
  //     axios
  //       .get(`${process.env.REACT_APP_BACKEND_API_URL}/rsvp/${slug}/`)
  //       .then((response) => {
  //         setGuest(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching RSVP data", error);
  //       });
  //   }
  // }, [slug]);

  const handleButtonClick = () => {
    audio.play();
    if (onButtonClick) onButtonClick();
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      flexDirection="column"
      style={{
        height: "100vh",
        width: "100%",
        my: 20,
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          paddingLeft: 3,
          paddingRight: 3,
          paddingBottom: 10,
        }}
      >
        <Typography
          style={{
            fontSize: 19,
            fontFamily: "Balerno-Bold",
            textAlign: "center",
          }}
        >
          TO
        </Typography>
        {guest ? (
          <Typography
            sx={{
              fontFamily: "Balerno-Bold",
              fontSize: 30,
              paddingTop: 2.5,
              paddingBottom: 2.5,
              textAlign: "center",
              px: { md: 10, sm: 5, xs: 3 },
            }}
          >
            {" "}
            {guest.name} {guest.additional}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontFamily: "Balerno-Bold",
              fontSize: 30,
              paddingTop: 2.5,
              paddingBottom: 2.5,
              textAlign: "center",
            }}
          >
            Our Honoured Guests
          </Typography>
        )}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{
          paddingTop: "25px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          onClick={handleButtonClick}
          style={{
            backgroundColor: "#8fa18b",
            color: "#fff",
            borderRadius: 0,
            fontSize: 18,
            fontFamily: "Balerno-Bold",
            textTransform: "none",
            width: "250px",
          }}
        >
          View Invitation
        </Button>
      </motion.div>
      <img
        src={Background}
        style={{
          position: "absolute",
          bottom: 0,
          top: 0,
          height: "100vh",
          width: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      />
    </Box>
  );
};

export default ViewInvitation;
